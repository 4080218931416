// External packages
import * as React from "react"
import { Box } from "theme-ui"
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react"

// Types
import { ContentfulGrid } from "types"

// Components
import { Icon } from "../ui/Icon"
import { UiGridItem } from "../ui/Contentful"
import { Product } from "./Product"
import { TileV2 } from "./TileV2"
import { Button } from "./Button"
import { Text } from "./Text"
import { Image } from "./Image"
import { WidgetDrawer } from "./WidgetDrawer"
import { WidgetLink } from "./WidgetLink"

// Utilities
import {
  isContentfulButton,
  isContentfulImage,
  isContentfulProduct,
  isContentfulText,
  isContentfulTileV2,
  isContentfulWidgetDrawer,
  isContentfulWidgetLink,
  isContentfulWidgetShopTheLook,
} from "./utils"

// Assets
import "swiper/css"
import { WidgetShopTheLook } from "./WidgetShopTheLook"

export const Grid: React.FC<{
  data: ContentfulGrid
  isInBlogPost: boolean
}> = ({ data, isInBlogPost }) => {
  const gridRef = React.useRef(null)
  const swiperRef = React.useRef(null)

  const getRows = (element: HTMLElement[]) => {
    const tolerance = 1
    const rows = new Array()

    // Group elements by their bottom position
    element.forEach((element) => {
      // Get the bottom position of the element
      const bottom = element.offsetTop + element.offsetHeight
      // Find a row with the same bottom position
      const sameRowArray = rows.find((row) => {
        const rowBottom = row[0].offsetTop + row[0].offsetHeight
        return Math.floor(Math.abs(rowBottom - bottom)) <= tolerance
      })

      // If a row with the same bottom position exists and element is not a
      // widget, add the element to that row, otherwise create a new row
      if (element.dataset.textual !== "true") {
        if (rows.length > 0 && Boolean(sameRowArray)) {
          sameRowArray.push(element)
        } else {
          rows.push([element])
        }
      }
    })

    return rows
  }

  React.useEffect(() => {
    if (!gridRef.current) {
      return
    }

    // Get elements grouped by their bottom position
    const rows = getRows(Array.from(gridRef.current.children))

    const handleResize = () => {
      // Set the height of each image element to the highest footer height in the row
      rows.forEach((row) => {
        const highestFooterHeight = Math.max(
          ...row.map((element: HTMLElement) => {
            const footerElement = element.querySelector(
              "[data-footer]"
            ) as HTMLElement
            if (Boolean(footerElement) === false) return
            return footerElement.offsetHeight
          })
        )

        row.forEach((item: HTMLElement) => {
          const imageElement = item.querySelector("[data-image]") as HTMLElement
          const footerElement = item.querySelector(
            "[data-footer]"
          ) as HTMLElement
          if (Boolean(imageElement)) {
            imageElement.style.height = `calc(100% - ${
              highestFooterHeight + "px"
            })`
          }
          if (Boolean(footerElement)) {
            footerElement.style.height = highestFooterHeight + "px"
          }
        })
      })
    }

    handleResize()

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [data, gridRef.current])

  const handleNavigationDisable = (swiper: SwiperClass) => {
    swiper.el.parentElement
      .querySelector(".swiper-button-previous")
      .setAttribute(
        "data-disabled",
        swiperRef.current?.isBeginning === true ? "true" : "false"
      )
    swiper.el.parentElement
      .querySelector(".swiper-button-next")
      .setAttribute(
        "data-disabled",
        swiperRef.current?.isEnd === true ? "true" : "false"
      )
  }

  const handleNavigationPosition = (swiper: SwiperClass) => {
    const hasPicture = swiper.slides.some(
      (slide) => slide.querySelector("picture") !== null
    )

    if (!hasPicture) return

    const height = swiper.slides.reduce((max, slide) => {
      return Math.max(max, slide.querySelector("picture")?.offsetHeight || 0)
    }, 0)

    swiper.el.parentElement
      .querySelectorAll("[class^=swiper-button-]")
      .forEach(
        (el) => ((el as HTMLElement).style.top = Math.round(height / 2) + "px")
      )
  }

  if (!data.contentModules?.length) {
    return null
  }

  return (
    <>
      {data?.desktopLayout === "Slider" ? (
        <Box
          sx={{
            display: ["none", "block"],
            position: "relative",
            marginBlockStart: data?.topMargin,
            marginBlockEnd: data?.bottomMargin,
            ".swiper": { paddingInline: data?.sideMargins },
            ".swiper-slide": { height: "auto" },
          }}
        >
          <Swiper
            slidesPerView={
              data?.gridItems === "2" ? 2 : data?.gridItems === "3" ? 3 : 4
            }
            spaceBetween={data?.gapColumn}
            cssMode={true}
            onBeforeInit={(swiper) => (swiperRef.current = swiper)}
            onInit={(swiper) => handleNavigationDisable(swiper)}
            onSlideChange={(swiper) => handleNavigationDisable(swiper)}
            onResize={(swiper) => handleNavigationPosition(swiper)}
          >
            {data.contentModules.map((item, index) => {
              return (
                <SwiperSlide>
                  {isContentfulProduct(item) ? (
                    <Product
                      key={item.id}
                      data={item}
                      index={index}
                      hasStickyTitles={data?.stickyTitles}
                      gridItems={data?.gridItems}
                      mobileLayout={data?.mobileLayout}
                      sideMargins={data?.sideMargins}
                    />
                  ) : null}

                  {isContentfulTileV2(item) ? (
                    <TileV2
                      key={item.id}
                      data={item}
                      index={index}
                      hasStickyTitles={data?.stickyTitles}
                      gridItems={data?.gridItems}
                      mobileLayout={data?.mobileLayout}
                      sideMargins={data?.sideMargins}
                    />
                  ) : null}

                  {isContentfulButton(item) ? (
                    <Button key={item.id} data={item} />
                  ) : null}

                  {isContentfulText(item) ? (
                    <Text key={item.id} data={item} />
                  ) : null}

                  {isContentfulImage(item) ? (
                    <Image key={item.id} data={item} />
                  ) : null}

                  {isContentfulWidgetDrawer(item) ? (
                    <WidgetDrawer key={item.id} data={item} />
                  ) : null}

                  {isContentfulWidgetLink(item) ? (
                    <WidgetLink
                      key={item.id}
                      data={item}
                      index={index}
                      gridItems={data?.gridItems}
                      mobileLayout={data?.mobileLayout}
                      hasStickyTitles={data?.stickyTitles}
                    />
                  ) : null}

                  {isContentfulWidgetShopTheLook(item) ? (
                    <WidgetShopTheLook
                      key={item.id}
                      data={item}
                      sideMargins={data?.sideMargins}
                    />
                  ) : null}
                </SwiperSlide>
              )
            })}
          </Swiper>
          <Box
            as="button"
            className="swiper-button-previous"
            sx={{
              cursor: "pointer",
              border: 0,
              backgroundColor: "transparent",
              position: "absolute",
              top: "50%",
              left: data?.sideMargins,
              transform: "translateY(-50%) translateX(-50%)",
              zIndex: 1,
              padding: 0,
              "&[data-disabled=true]": {
                display: "none",
              },
            }}
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <Icon name="arrow-left" size={7} />
          </Box>
          <Box
            as="button"
            className="swiper-button-next"
            sx={{
              cursor: "pointer",
              border: 0,
              backgroundColor: "transparent",
              position: "absolute",
              top: "50%",
              right: data?.sideMargins,
              transform: "translateY(-50%) translateX(50%)",
              zIndex: 1,
              padding: 0,
              "&[data-disabled=true]": {
                display: "none",
              },
            }}
            onClick={() => swiperRef.current?.slideNext()}
          >
            <Icon name="arrow-right" size={7} />
          </Box>
        </Box>
      ) : null}
      <Box
        ref={gridRef}
        sx={{
          display:
            data?.desktopLayout === "Slider" && data?.mobileLayout === "Slider"
              ? ["flex", "none"]
              : data?.desktopLayout === "Slider" &&
                data?.mobileLayout === "Horizontal"
              ? ["grid", "none"]
              : data?.desktopLayout === "Slider" &&
                data?.mobileLayout === "Vertical"
              ? ["flex", "none"]
              : data?.mobileLayout === "Slider"
              ? ["flex", "grid"]
              : "grid",
          gridAutoFlow: "dense",
          gridTemplateColumns:
            (data?.gridItems === "4+1" || data?.gridItems === "1+4") &&
            data?.mobileLayout === "Slider"
              ? [`repeat(${data?.gridItems}, 1fr)`, "repeat(4, 1fr)"]
              : (data?.gridItems === "4+1" || data?.gridItems === "1+4") &&
                data?.mobileLayout === "Horizontal"
              ? ["1fr 1fr", "repeat(4, 1fr)"]
              : (data?.gridItems === "4+1" || data?.gridItems === "1+4") &&
                data?.mobileLayout === "Vertical"
              ? ["1fr", "repeat(4, 1fr)"]
              : (data?.gridItems === "2" ||
                  data?.gridItems === "3" ||
                  data?.gridItems === "4") &&
                data?.mobileLayout === "Vertical"
              ? ["1fr", `repeat(${data?.gridItems}, 1fr)`]
              : (data?.gridItems === "2" ||
                  data?.gridItems === "3" ||
                  data?.gridItems === "4") &&
                data?.mobileLayout === "Horizontal"
              ? ["1fr 1fr", `repeat(${data?.gridItems}, 1fr)`]
              : (data?.gridItems === "2" ||
                  data?.gridItems === "3" ||
                  data?.gridItems === "4") &&
                data?.mobileLayout === "Slider"
              ? `repeat(${data?.gridItems}, 1fr)`
              : null,
          gridTemplateRows: "1fr",
          columnGap: data?.gapColumn,
          rowGap: data?.gapRow,
          overflowX:
            data?.mobileLayout === "Slider" ? ["scroll", "unset"] : null,
          marginBlockStart: data?.topMargin,
          marginBlockEnd: data?.bottomMargin,
          paddingInline: data?.sideMargins,
          scrollbarWidth:
            data?.mobileLayout === "Slider" ? ["none", "unset"] : null,
          "::-webkit-scrollbar": {
            display: data?.mobileLayout === "Slider" ? ["none", "unset"] : null,
          },
        }}
      >
        {data.contentModules.map((item, index) => {
          if (isContentfulProduct(item)) {
            return (
              <Product
                key={item.id}
                data={item}
                index={index}
                hasStickyTitles={data?.stickyTitles}
                gridItems={data?.gridItems}
                mobileLayout={data?.mobileLayout}
                sideMargins={data?.sideMargins}
              />
            )
          }

          if (isContentfulTileV2(item)) {
            return (
              <TileV2
                key={item.id}
                data={item}
                index={index}
                hasStickyTitles={data?.stickyTitles}
                gridItems={data?.gridItems}
                mobileLayout={data?.mobileLayout}
                sideMargins={data?.sideMargins}
              />
            )
          }

          if (isContentfulButton(item)) {
            return (
              <UiGridItem
                index={index}
                gridItems={data?.gridItems}
                mobileLayout={data?.mobileLayout}
              >
                <Button key={item.id} data={item} />
              </UiGridItem>
            )
          }

          if (isContentfulText(item)) {
            return (
              <UiGridItem
                index={index}
                gridItems={data?.gridItems}
                mobileLayout={data?.mobileLayout}
                sx={{
                  paddingBlockStart:
                    data?.mobileLayout === "Vertical" &&
                    index === 0 &&
                    isInBlogPost === false
                      ? 25
                      : data?.mobileLayout === "Vertical" &&
                        isInBlogPost === false
                      ? 19
                      : null,
                  paddingBlockEnd:
                    data?.mobileLayout === "Vertical" && isInBlogPost === false
                      ? 25
                      : null,
                }}
              >
                <Text key={item.id} data={item} sx={{ height: "100%" }} />
              </UiGridItem>
            )
          }

          if (isContentfulImage(item)) {
            return (
              <UiGridItem
                index={index}
                gridItems={data?.gridItems}
                mobileLayout={data?.mobileLayout}
              >
                <Image key={item.id} data={item} />
              </UiGridItem>
            )
          }

          if (isContentfulWidgetDrawer(item)) {
            return (
              <WidgetDrawer
                key={item.id}
                data={item}
                index={index}
                gridItems={data?.gridItems}
                mobileLayout={data?.mobileLayout}
                hasStickyTitles={data?.stickyTitles}
              />
            )
          }

          if (isContentfulWidgetLink(item)) {
            return (
              <WidgetLink
                key={item.id}
                data={item}
                index={index}
                gridItems={data?.gridItems}
                mobileLayout={data?.mobileLayout}
                hasStickyTitles={data?.stickyTitles}
              />
            )
          }

          if (isContentfulWidgetShopTheLook(item)) {
            return (
              <WidgetShopTheLook
                key={item.id}
                data={item}
                index={index}
                gridItems={data?.gridItems}
                mobileLayout={data?.mobileLayout}
                hasStickyTitles={data?.stickyTitles}
                sideMargins={data?.sideMargins}
              />
            )
          }

          return null
        })}
      </Box>
    </>
  )
}
