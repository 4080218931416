// Services
import { trackAddToCart } from "../../../../services/analytics"

// Utilities
import { getToweLabel } from "../../utils/product/getTowelLabel"
import { sizeToInches } from "../../../../utils/functions/product-util"
import { isDimensionalSize } from "../../../../utils/functions/product-util"

// Types
import {
  PricedProduct,
  PricedVariant,
} from "@medusajs/medusa/dist/types/pricing"

import type { Cart, ProductCategory } from "@medusajs/medusa"
import { useCreateLineItem } from "medusa-react"

interface SelectedVariant extends PricedVariant {
  quantity: number
}

type handleAddToCart = {
  product: {
    title: string
    subcategory: ProductCategory["name"]
    thumbnail?: string
    color?: string
  }
  variant: Pick<
    SelectedVariant,
    "id" | "sku" | "quantity" | "title" | "metadata" | "inventory_quantity"
  >
  cart?: Readonly<Cart>
  createLineItem?: ReturnType<typeof useCreateLineItem>
  onAddToCart?: (content: string) => void
  quickAddToCart?: boolean
}

export const handleAddToCart = async ({
  product: product,
  variant: variant,
  onAddToCart: onAddToCart,
  cart: cart,
  createLineItem: createLineItem,
  quickAddToCart: quickAddToCart = false,
}: handleAddToCart) => {
  if (createLineItem.isLoading) {
    return
  }

  let notificationContent

  const quantity = variant.quantity ?? 1
  let metadata: any = {
    type: variant?.sku?.startsWith("TT") ? getToweLabel(variant.title) : "",
  }

  let title = variant?.sku?.startsWith("TT")
    ? getToweLabel(variant.title)
    : variant.title

  if (isDimensionalSize(variant?.title) && !variant?.sku?.startsWith("TT")) {
    const sizeInInches = sizeToInches(variant) + "in"
    title =
      cart?.shipping_address?.country_code?.toLowerCase() === "us"
        ? sizeInInches
        : variant.title
    metadata.sizeInInches = sizeInInches
  }

  try {
    await createLineItem.mutateAsync({
      variant_id: variant.id,
      quantity: quantity,
      metadata: metadata,
    })

    notificationContent = `${product.title} - ${quantity} x ${title} has been added to your cart`
  } catch (error) {
    notificationContent =
      "Something went wrong. Item can't be added to the cart."
    console.log(error)
  }

  onAddToCart(notificationContent)

  trackAddToCart(cart, product, variant, quantity, quickAddToCart)
}
