// External packages
import * as React from "react"
import { Box, BoxProps } from "theme-ui"

// Types
import { ContentfulGrid } from "types"

export const UiGridItem: React.FC<
  BoxProps & {
    index: number
    gridItems: ContentfulGrid["gridItems"]
    mobileLayout: ContentfulGrid["mobileLayout"]
  }
> = ({ index, gridItems, mobileLayout, sx, ...rest }) => (
  <Box
    {...rest}
    sx={{
      ...sx,
      width: mobileLayout === "Slider" ? ["75%", "unset"] : null,
      flexBasis: mobileLayout === "Slider" ? ["75%", "unset"] : null,
      flexShrink: 0,
      flexGrow: 0,
      gridColumn:
        gridItems === "4+1" &&
        index === 2 &&
        (mobileLayout === "Vertical" || mobileLayout === "Slider")
          ? [null, "3 / span 2"]
          : gridItems === "4+1" && index === 2 && mobileLayout === "Horizontal"
          ? ["span 2", "3 / span 2"]
          : gridItems === "1+4" &&
            index === 0 &&
            (mobileLayout === "Vertical" || mobileLayout === "Slider")
          ? [null, "1 / span 2"]
          : gridItems === "1+4" && index === 0 && mobileLayout === "Horizontal"
          ? "1 / span 2"
          : null,
      gridRow:
        (gridItems === "4+1" && index === 2 && mobileLayout === "Vertical") ||
        (gridItems === "1+4" && index === 0 && mobileLayout === "Vertical")
          ? [null, "span 2"]
          : (gridItems === "4+1" && index === 2) ||
            (gridItems === "1+4" && index === 0)
          ? "span 2"
          : null,
    }}
  />
)
